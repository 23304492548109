// src/pages/Index.js
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import { useParams } from "react-router-dom";
import { GetProduct } from "../../infra/requests/ProductsRequests";
import ProductCard from "./ProductCard";
import "./styles.scss"; // Import the styles.scss file
import UiTabs from "../../Components/Common/Tabs";
import ProductDetail from "./ProductDetail/ProductDetail";

const Index = () => {
  const [product, setProduct] = useState({});
  const [margin, setMargin] = useState();
  const { productId } = useParams();
  const currencies = useSelector((state) => state.Info.data.currencies);
  const [lastAcquisition, setLastAcquisition] = useState();
  const [tab, setTab] = useState(1);

  const breadcrumbData = {
    pageTitle: "Articles Details",
    items: [{ title: "Article", url: "/articles" }],
  };

  const items = [
    { id: 1, title: "Properties" },
    { id: 2, title: "SKU" },
    { id: 3, title: "Composition" },
    { id: 4, title: "Measure" },
    { id: 5, title: "Price" },
    { id: 6, title: "Online Store" },
    { id: 7, title: "Picture" },
  ];

  useEffect(() => {
    const fetchProduct = async () => {
      const { data } = await GetProduct(productId);
      setProduct(data);

      //check if there is margin from the api , otherwise calculate on frontend
      const marginPercentage = data.productPriceSection?.marginPercentage;

      //Futura verificação para o campo lastAcquisition
      calculateAcquisition(data);

      if (marginPercentage) {
        const calculatedMargin = calculateGrossMargin(data);
        setMargin(calculatedMargin);
      } else {
        setMargin(marginPercentage);
      }
    };
    fetchProduct();
  }, [productId]);

  const calculateAcquisition = (product) => {
    const productSuppliers =
      product.productCompositionSection?.productCostFiles[0]?.productSupplier ||
      [];

    const rawMaterialList =
      product.productCompositionSection?.productCostFiles[0]?.rawMaterialList ||
      [];

    let totalSupplierCost = 0;

    // Calculate total from productSupplier
    for (let index = 0; index < productSuppliers.length; index++) {
      const supplierCost = productSuppliers[index].cost;
      const supplierUnits = productSuppliers[index].units || 0;
      totalSupplierCost += supplierCost * supplierUnits; // Sum costs multiplied by units
    }

    let totalRawMaterialCost = 0;

    // Calculate total from rawMaterialList
    for (let index = 0; index < rawMaterialList.length; index++) {
      const rawMaterialCost = rawMaterialList[index].baseValue;
      const rawMaterialQuantity = rawMaterialList[index].quantity || 0;
      totalRawMaterialCost += rawMaterialCost * rawMaterialQuantity;
    }

    const totalCost = totalSupplierCost + totalRawMaterialCost;

    setLastAcquisition(totalCost);
  };

  const calculateGrossMargin = (product) => {
    const baseValue = product.productPriceSection?.baseValue;
    if (!baseValue) {
      return 0;
    }

    const productSuppliers =
      product.productCompositionSection?.productCostFiles[0]?.productSupplier ||
      [];

    const rawMaterialList =
      product.productCompositionSection?.productCostFiles[0]?.rawMaterialList ||
      [];

    let totalSupplierCost = 0;

    // Calculate total from productSupplier
    for (let index = 0; index < productSuppliers.length; index++) {
      const supplierCost = productSuppliers[index].cost;
      const supplierUnits = productSuppliers[index].units || 0;
      totalSupplierCost += supplierCost * supplierUnits; // Sum costs multiplied by units
    }

    let totalRawMaterialCost = 0;

    // Calculate total from rawMaterialList
    for (let index = 0; index < rawMaterialList.length; index++) {
      const rawMaterialCost = rawMaterialList[index].baseValue;
      const rawMaterialQuantity = rawMaterialList[index].quantity || 0;
      totalRawMaterialCost += rawMaterialCost * rawMaterialQuantity;
    }

    const totalCost = totalSupplierCost + totalRawMaterialCost;

    // Avoid division by zero
    if (totalCost === 0) {
      return 0;
    }

    const grossMargin = 1 - totalCost / baseValue;
    return grossMargin * 100; // Convert to percentage
  };

  const baseValue = product.productPriceSection?.baseValue;
  const otherPrices = product.productPriceSection?.productPrice || [];
  const additionalPrice = otherPrices.find((price) => price.currencyId !== 1);

  const getCurrencySymbol = (currencyId) => {
    const currency = currencies.find((curr) => curr.currencyId === currencyId);
    return currency ? currency.symbol : "";
  };

  const renderTabContent = () => {
    switch (tab) {
      case 1:
        return <ProductDetail />;
      case 2:
        return <div>SKU Content</div>;
      case 3:
        return <div>Composition Content</div>;
      case 4:
        return <div>Measure Content</div>;
      case 5:
        return <div>Price Content</div>;
      case 6:
        return <div>Online Store Content</div>;
      case 7:
        return <div>Picture Content</div>;
      default:
        return <div>Default Content</div>;
    }
  };

  return (
    <div className="page-content">
      <Breadcrumb breadcrumbData={breadcrumbData} />
      <ProductCard
        product={product}
        margin={margin}
        lastAcquisition={lastAcquisition}
        baseValue={baseValue}
        additionalPrice={additionalPrice}
        getCurrencySymbol={getCurrencySymbol}
      />
      <UiTabs tab={tab} setTab={setTab} options={items} />
      {renderTabContent()}
    </div>
  );
};

export default Index;
