import React from "react";
import { Label } from "reactstrap";
import { DatePickerInput } from "@mantine/dates";

const DateInput = ({
  label,
  required,
  subtitle,
  value,
  onChange,
  name,
  id,
  placeholder,
  type,
}) => {
  return (
    <div className="mt-2">
      {label && (
        <Label className="heading_4" htmlFor={name}>
          {label}
          {required && <span className="text-danger"> *</span>}
        </Label>
      )}
      <DatePickerInput
        placeholder={placeholder}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        type={type}
        withCellSpacing={false}
        valueFormat="DD-MM-YYYY"
        size="md"
      />
      {subtitle && (
        <div
          style={{ marginTop: 5 }}
          className="bodyText_11 input_subtitle_text"
        >
          {subtitle}
        </div>
      )}
    </div>
  );
};

export default DateInput;
