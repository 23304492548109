import React from "react";
import { Card } from "reactstrap";

const ProductDetail = () => {

  return (
    <Card className="card-form">
    </Card>
  );
};

export default ProductDetail;
