import React, { useState, useEffect } from "react";
import { Combobox, Pill, PillsInput, useCombobox, Input } from "@mantine/core";
import { Label } from "reactstrap";
import Icon from "../../icon";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

const MultipleSelectInput = ({
  required,
  name,
  subtitle,
  label,
  options,
  placeholder,
  searchable,
  onChange,
  value,
  id,
  disabled,
  clearable,
  hasCloseOnOption,
}) => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState([]);
  const [search, setSearch] = useState("");
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch("");
    },
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex("active");
      searchable && combobox.focusSearchInput();
    },
  });

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue]);

  const handleValueSelect = (val) => {
    setCurrentValue((current) =>
      current.includes(val)
        ? current.filter((v) => v !== val)
        : [...current, val]
    );
    setSearch("");
  };

  const handleDisplayValue = (val) => {
    const index = options?.findIndex((item) => item.value === val);
    return index !== -1 ? options[index].label : null;
  };

  const handleValueRemove = (val) => {
    setCurrentValue((current) => current.filter((v) => v !== val));
  };

  const values = currentValue?.map((item, index) => {
    return (
      <Pill
        key={index}
        withRemoveButton={hasCloseOnOption ? true : false}
        onRemove={() => handleValueRemove(item)}
        className={
          hasCloseOnOption
            ? "multiple-select-pill-with-x"
            : "multiple-select-pill"
        }
      >
        {handleDisplayValue(item)}
      </Pill>
    );
  });

  const currentOptions = options
    .filter((item) =>
      item?.label?.toLowerCase().includes(search.toLowerCase().trim())
    )
    .filter((item) => {
      return !currentValue.includes(item.value);
    })
    .map((item, index) => (
      <Combobox.Option
        value={item.value}
        key={index}
        // active={currentValue.includes(item.value)}
        active={index === 0 ? true : false}
      >
        {item?.label}
      </Combobox.Option>
    ));

  return (
    <div className="mt-2">
      {label && (
        <Label className="heading_4" htmlFor={name}>
          {label}
          {required && <span className="text-danger"> *</span>}
        </Label>
      )}
      <Combobox
        disabled={disabled}
        store={combobox}
        onOptionSubmit={handleValueSelect}
        withinPortal={false}
        position="bottom"
        middlewares={{ flip: false, shift: false }}
      >
        <Combobox.DropdownTarget>
          <PillsInput
            disabled={disabled}
            pointer
            onClick={() => combobox.toggleDropdown()}
            name={name}
            id={id}
            rightSectionPointerEvents={currentValue === null ? "none" : "all"}
            rightSection={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: 24,
                }}
              >
                {clearable && currentValue !== null && (
                  <div
                    onMouseDown={(event) => event.preventDefault()}
                    onClick={() => setCurrentValue(null)}
                    aria-label="Clear value"
                  >
                    <Icon
                      className="comboBox-close-icon"
                      style={{ marginRight: 10 }}
                      icon="closeIcon"
                      size={[18, 18]}
                    />
                  </div>
                )}
                <Icon icon="downArrowIcon" size={[11, 7]} />
              </div>
            }
          >
            <Pill.Group>
              {values.length > 0 ? (
                values
              ) : (
                <Input.Placeholder>{placeholder}</Input.Placeholder>
              )}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  type="hidden"
                  onKeyDown={(event) => {
                    if (event.key === "Backspace") {
                      event.preventDefault();
                      handleValueRemove(currentValue[currentValue.length - 1]);
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown>
          {searchable && (
            <Combobox.Search
              rightSectionPointerEvents={value === null ? "none" : "all"}
              value={search}
              onChange={(event) => setSearch(event.currentTarget.value)}
              placeholder={`${t("SEARCH")}...`}
              rightSection={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: 24,
                  }}
                >
                  {search !== "" ? (
                    <div
                      onMouseDown={(event) => event.preventDefault()}
                      onClick={() => setSearch("")}
                      aria-label="Clear value"
                    >
                      <Icon
                        className="comboBox-close-icon"
                        icon="closeIcon"
                        size={[18, 18]}
                      />
                    </div>
                  ) : (
                    <Icon icon="searchIcon" size={[15, 15]} />
                  )}
                </div>
              }
            />
          )}
          <SimpleBar style={{ maxHeight: "320px" }} autoHide={false}>
            <Combobox.Options>
              {currentOptions.length === 0 ? (
                <Combobox.Empty>{t("ALL_OPTIONS_SELECTED")}</Combobox.Empty>
              ) : (
                currentOptions
              )}
            </Combobox.Options>
          </SimpleBar>
        </Combobox.Dropdown>
      </Combobox>
      {subtitle && (
        <div
          style={{ marginTop: 5 }}
          className="bodyText_11 input_subtitle_text"
        >
          {subtitle}
        </div>
      )}
    </div>
  );
};

export default MultipleSelectInput;
