import React from "react";
import { Button } from "reactstrap";
import Icon from "../icon";

const ButtonIcon = ({ type, disabled, color, icon, alert, round }) => {
  const handleType = () => {
    switch (type) {
      case "secondary":
        return "icon-btn-secondary";
      case "noBorder":
        return "icon-btn-noBorder";
      default:
        return "icon-btn-default";
    }
  };

  const handleColor = () => {
    switch (color) {
      case "green":
        return "green";
      case "yellow":
        return "yellow";
      case "red":
        return "red";
      case "grey":
        return "grey";
      case "light-grey":
        return "light-grey ";
      case "black":
        return "black";
      default:
        return "";
    }
  };

  const handleRound = () => {
    if (round) {
      return "round";
    }
    return "";
  };

  return (
    <Button
      disabled={disabled}
      className={`icon-btn-icon ${handleColor()} ${handleType()} ${handleRound()}`}
      style={{ position: "relative" }}
    >
      {alert && <div className="base-btn-alert" />}
      <Icon icon={icon} size={[16, 16]} />
    </Button>
  );
};

export default ButtonIcon;
