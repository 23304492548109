import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ImageColumn from "./columns/ImageFormat";
import DefaultColumn from "./columns/DefaultFormat";
import { InputText } from "primereact/inputtext";
import BoxNumber from "./columns/BoxNumber";
import NumberStatus from "./columns/NumberStatus";
import StatusColumn from "./columns/statusColumn";
import QuantityColumn from "./columns/QuantityColumn";
import QuantityDateColumn from "./columns/QuantityDateColumn";
import CurrencyColumn from "./columns/Currency";
import ProgressColumn from "./columns/progressColumn";
import { InputNumber } from "primereact/inputnumber";
import SelectInput from "../Inputs/SelectInput/SelectInput";
import PaginationComponent from "../PaginationComponent";
import TableButton from "../Button";
import ButtonIcon from "../Buttons/ButtonIcon";
import Icon from "../icon";
// import MultipleDropDown from "../Inputs/MultipleDropDown/MultipleDropDown";
import DropDown from "../Inputs/DropDown/DropDown";
import Badge from "../Badges/Badge";
import BatteryBadge from "../Badges/BatteryBadge";
import { useNavigate } from "react-router-dom";

const Table = ({
  tableInfo,
  tableColumns,
  columnFilters,
  setColumnFilters,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalItems,
  setList,
  loading,
  getRowUrl
}) => {
  // State to manage the products data
  const [selectedProducts, setSelectedProducts] = useState(null);
  const navigate = useNavigate();

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(totalItems / rowsPerPage);

  // Update products state when row editing is complete
  const onRowEditComplete = (e) => {
    let _products = [...tableInfo];
    const { newData, index } = e;
    _products[index] = newData;
    setList(_products);
  };

  // Render appropriate editor component based on field type
  const editorComponent = (columns, options, column) => {
    const matchedColumn = columns.find((col) => col.id === column.field);

    if (matchedColumn) {
      switch (matchedColumn.rowEditType) {
        case "progress":
          return (
            <InputText
              size={15}
              type="text"
              value={options.value}
              onChange={(e) => options.editorCallback(e.target.value)}
            />
          );
        case "number":
          return (
            <InputNumber
              value={options.value}
              onValueChange={(e) => options.editorCallback(e.value)}
            />
          );
        case "quantity":
          return (
            <InputText
              size={15}
              type="text"
              value={options.value}
              onChange={(e) => options.editorCallback(e.target.value)}
            />
          );
        case "currency":
          return (
            <InputNumber
              value={options.value}
              onValueChange={(e) => options.editorCallback(e.value)}
              mode="currency"
              currency="EUR"
            />
          );
        case "dropdown":
          return (
            <div>
              <SelectInput
                style={{ width: "150px" }}
                name="select"
                id="select"
                placeholder="Select"
                onChange={(selectedOption) => {
                  const selectedValue = matchedColumn.editData.find(
                    (item) => item.label === selectedOption.label
                  )?.value;
                  options.editorCallback(selectedValue);
                }}
                options={matchedColumn.editData.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
              />
            </div>
          );
        case "dropdown-search":
          return (
            <div>
              <SelectInput
                style={{ width: "150px" }}
                name="select"
                id="select"
                placeholder="Select an Order Status"
                onChange={(value) => options.editorCallback(value)}
                options={matchedColumn.editData.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
              />
            </div>
          );
        default:
          return <InputText />;
      }
    } else {
      return null;
    }
  };

  const handleRowsPerPageChange = (value) => {
    setRowsPerPage(value);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };

  const onRowClick = (rowData) => {
    const url = getRowUrl(rowData); // Use the function prop to generate the URL
    navigate(url);
  };

  return (
    <div className="table-wrapper">
      <DataTable
        size={tableColumns.size}
        scrollable={tableColumns.scroll.scrollable}
        scrollHeight={tableColumns.scroll.scrollableHeight}
        selection={selectedProducts}
        onSelectionChange={(e) => setSelectedProducts(e.value)}
        editMode="row"
        value={tableInfo}
        globalFilterFields={tableColumns.globalFilterFields}
        className="custom-datatable"
        onRowEditComplete={onRowEditComplete}
        paginator={false}
        rowClassName="clickable-row"
        onRowClick={(e) => onRowClick(e.data)}
      >
        <Column headerStyle={{ width: "15px" }} />

        {tableColumns?.columns?.map((column, index) => {
          return (
            <Column
              selectionMode={column?.type === "check" ? "multiple" : undefined}
              key={index}
              editor={(options) =>
                editorComponent(tableColumns.columns, options, column)
              }
              field={column.field}
              filter={column.filter}
              className="custom-column"
              showFilterMenu={false}
              footer={() => {
                if (loading) {
                  return <div />;
                } else {
                  return (
                    <div
                      className="table-column-filter-wrapper-footer"
                      style={{
                        justifyContent: column.align ? column.align : "center",
                        padding: 0,
                      }}
                    >
                      {column.footer}
                    </div>
                  );
                }
              }}
              header={
                <div
                  className="table-column-filter-wrapper"
                  style={{
                    justifyContent: column.align ? column.align : "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: 14,
                      lineHeight: "normal",
                      justifyContent: "flex-start",
                    }}
                  >
                    {column.header}
                  </div>
                  {column?.columnFilter === "single" && (
                    <DropDown
                      top={13}
                      style={{ marginTop: "0px !important" }}
                      searchable={true}
                      placeholder="Search..."
                      onChange={(e) => {
                        setColumnFilters({
                          ...columnFilters,
                          [column?.filterName]: e.length === 0 ? "" : e,
                        });
                      }}
                      value={
                        columnFilters[column?.filterName] !== undefined
                          ? columnFilters[column?.filterName]
                          : undefined
                      }
                      options={column.filters}
                    >
                      <div>
                        <Icon
                          className="table-column-filter-icon"
                          icon="columnFilterIcon"
                          size={[15, 15]}
                        />
                      </div>
                    </DropDown>
                  )}
                </div>
              }
              alignHeader={"center"}
              body={(rowData) => {
                if (loading) {
                  return (
                    <div
                      style={{
                        minHeight: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="placeholder-md" />
                    </div>
                  );
                } else {
                  // Find the matching column in tableColumns

                  const matchedColumn = tableColumns?.columns?.find(
                    (col) => col.field === column.field
                  );

                  if (matchedColumn) {
                    // Use the type from matchedColumn to render the data
                    switch (matchedColumn.type) {
                      case "status-tag":
                        return <StatusColumn data={rowData} />;
                      case "image-component":
                        return <ImageColumn data={rowData} />;
                      case "box-number":
                        return (
                          <BoxNumber
                            data={rowData[column.field]}
                            products={tableInfo}
                          />
                        );
                      case "progress":
                        return (
                          <ProgressColumn
                            data={rowData[column.field]}
                            products={tableInfo}
                          />
                        );
                      case "quantity":
                        return (
                          <QuantityColumn
                            data={rowData[column.field]}
                            products={tableInfo}
                          />
                        );
                      case "quantity-date":
                        return (
                          <QuantityDateColumn
                            data={rowData[column.field]}
                            date={rowData.forecastDate}
                            products={tableInfo}
                          />
                        );
                      case "number-status":
                        return (
                          <NumberStatus
                            data={rowData[column.field]}
                            products={tableInfo}
                          />
                        );
                      case "badge":
                        return (
                          <Badge
                            text={rowData[column.field]}
                            color={rowData[column.field]}
                          />
                        );
                      case "battery-badge":
                        return <BatteryBadge type={rowData[column.field]} />;
                      case "currency":
                        return <CurrencyColumn data={rowData} />;
                      // Add cases for other types if needed
                      default:
                        return (
                          <div style={{ textAlign: "center" }}>
                            {rowData[column.field]}
                          </div>
                        ); // Render the data as-is if no specific type is specified
                    }
                  } else {
                    return (
                      <DefaultColumn
                        data={rowData[column.field]}
                        products={tableInfo}
                      />
                    ); // Render the data as-is if no matching column is found in tableColumns
                  }
                }
              }}
            />
          );
        })}

        {tableColumns.editrow.hasEdit === true && (
          <Column
            rowEditor
            headerStyle={{ width: 100 }}
            bodyStyle={{ textAlign: "center" }}
            body={(rowData, options) => {
              if (loading) {
                return <div className="placeholder-md" />;
              } else {
                return (
                  <>
                    {options.rowEditor?.editing ? (
                      <>
                        <TableButton
                          iconName="DotIcon"
                          size="sm"
                          colorType="warning"
                          outline
                          onSaveClick={(e) =>
                            options.rowEditor?.onSaveClick &&
                            options.rowEditor?.onSaveClick(e)
                          }
                          tooltipOptions={{ position: "top" }}
                        />
                        <TableButton
                          iconName="DotIcon"
                          size="sm"
                          colorType="warning"
                          outline
                          onClick={(e) =>
                            options.rowEditor?.onCancelClick &&
                            options.rowEditor?.onCancelClick(e)
                          }
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          zIndex: 999,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ButtonIcon
                          type="secondary"
                          icon="treeDots"
                          color="yellow"
                        />

                        {/* <TableButton
                      iconName="DotIcon"
                      size="sm"
                      colorType="warning"
                      outline
                      // TODO edit function
                      // onInitClick={(e) =>
                      //   options.rowEditor?.onInitClick &&
                      //   options.rowEditor?.onInitClick(e)
                      // }
                    /> */}
                      </div>
                    )}
                  </>
                );
              }
            }}
          />
        )}
        <Column headerStyle={{ width: "15px" }} />
      </DataTable>
      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        products={tableInfo}
        totalItems={totalItems}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </div>
  );
};

export default Table;
