import React, { useState, useEffect } from "react";
import UiTabs from "../../../Components/Common/Tabs";
import BreadCrumb from "../../../Components/Common/Breadcrumb";
import Filters from "./Filters";
import { useSelector } from "react-redux";
import { GetB2bclientWorkgroupsDrop } from "../../../infra/requests/ClientRequests";
import Table from "../../../Components/Common/Table";
import {
  GetFullProductList,
  GetRawMaterials,
  GetServices,
} from "../../../infra/requests/ProductsRequests";
import { ConstructQuery } from "../../../shared/logic/queries/EndpointQueries";
import Icon from "../../../Components/Common/icon";

const Index = () => {
  const info = useSelector((state) => state.Info);
  const [tab, setTab] = useState(1);
  const [entities, setEntities] = useState([]);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(() => {
    const savedPage = localStorage.getItem("page");
    return savedPage ? JSON.parse(savedPage) : 1;
  });
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [prevTab, setPrevTab] = useState(1); // Track the previous tab

  const breadcrumbData = {
    pageTitle: "Orders",
    items: [{ title: "Products", url: "/products" }],
  };

  const [filters, setFilters] = useState(() => {
    const savedFilters = localStorage.getItem("filters");
    return savedFilters
      ? JSON.parse(savedFilters)
      : {
          search: ["search"],
          segment: ["search"],
          gender: ["search"],
          categories: ["search"],
          entities: ["search"],
        };
  });

  const [columnFilters, setColumnFilters] = useState(() => {
    const savedColumnFilters = localStorage.getItem("columnFilters");
    return savedColumnFilters
      ? JSON.parse(savedColumnFilters)
      : {
          manufactoring: "",
        };
  });

  const items = [
    { id: 1, title: "Products" },
    { id: 2, title: "Raw Material" },
    { id: 3, title: "Services" },
  ];

  // Save filters and columnFilters to local storage whenever they change
  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filters));
    localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
  }, [filters, columnFilters]);

  // Save page state in local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("page", JSON.stringify(page));
  }, [page]);

  // Fetch entities on mount
  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const { status: groupsStatus, data: groupsData } =
          await GetB2bclientWorkgroupsDrop();
        if (groupsStatus === 200) {
          setEntities(
            groupsData.items.map((item) => ({
              label: item.name,
              value: item.b2bclientId,
            }))
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchEntities();
  }, []);

  // Fetch data based on filters, columnFilters, page, pageSize, and tab
  useEffect(() => {
    const fetchData = async () => {
      try {
        const handleProductsType = () => {
          if (filters?.segment?.length > 0) {
            if (filters?.segment[0].value === "b2b") {
              return "true";
            }
            if (filters?.segment[0].value === "b2c") {
              return "false";
            }
          } else {
            return undefined;
          }
        };

        const requestFilters = {
          search: filters?.search[0]?.value || undefined,
          mainCategoryId: filters?.gender[0]?.value,
          categoryId: filters?.categories[0]?.value && !filters?.categories[0]?.mainCategory
            ? filters?.categories[0].value
            : filters?.categories[0]?.mainCategory,
          subCategoryId: filters?.categories[0]?.mainCategory
            ? filters?.categories[0]?.value
            : undefined,
          b2bclientId: filters?.entities[0]?.value,
          productTypeId: columnFilters?.manufactoring || undefined,
          IsB2C: handleProductsType(),
        };

        let result;
        if (tab === 1) {
          result = await GetFullProductList(
            page,
            pageSize,
            ConstructQuery(requestFilters)
          );
        } else if (tab === 2) {
          result = await GetRawMaterials(
            page,
            pageSize,
            ConstructQuery(requestFilters)
          );
        } else if (tab === 3) {
          result = await GetServices(
            page,
            pageSize,
            ConstructQuery(requestFilters)
          );
        }

        if (result?.status === 200) {
          const modifiedProductList = result?.data?.items?.map((item) => ({
            id: tab === 3 ? item?.serviceId : item?.productId,
            company: tab === 1 ? item?.company || "-" : "-",
            image: item?.productImage?.[0]?.image?.url,
            productName:
              tab === 3
                ? item?.serviceTranslation[0]?.name
                : item?.productTranslation[0]?.name || "-",
            referencecode: tab === 3 ? item?.reference : item?.referenceCode,
            type:
              tab === 3
                ? "Service"
                : item?.productType?.productTypeTranslation[0]?.name || "-",
            category:
              tab === 1
                ? item?.mainCategory?.categoryTranslation[1]?.name || "-"
                : "-",
            price: "120,00",
            active: item?.active
              ? item?.active === true
                ? "active"
                : "canceled"
              : "unknown",
          }));

          setList(modifiedProductList);
          setTotalItems(result?.data?.totalItems);
        } else {
          setList([]);
          setTotalItems(0);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [filters, columnFilters, page, pageSize, tab]);

  // Reset page when switching to a different tab for the first time
  useEffect(() => {
    if (prevTab !== tab) {
      setPage(1);
    }
    setPrevTab(tab); // Update previous tab state
  }, [tab]);

  const gender = info?.data?.productCategories
    ?.filter((cat) => !cat.parentCategoryId)
    .map((item) => ({
      value: item.categoryId,
      label: item.categoryTranslation[1].name,
    }));

  const handleCategories = () => {
    const onlyCategories = info?.data?.productCategories.filter(
      (item) => item.parentCategoryId
    );

    const categories = onlyCategories.map((item) => ({
      value: item.categoryId,
      label: item.categoryTranslation[1].name,
      options: onlyCategories
        .filter((i) => i.parentCategoryId === item.categoryId)
        .map((item) => ({
          value: item.categoryId,
          label: item.categoryTranslation[1].name,
        })),
    }));

    const filteredData = categories.filter((item) => {
      return !categories.some((otherItem) => {
        if (otherItem.options) {
          return otherItem.options.some(
            (option) => option.value === item.value
          );
        }
        return false;
      });
    });

    const uniqueArray = filteredData.filter((item, index, self) => {
      return index === self.findIndex((t) => t.label === item.label);
    });

    return uniqueArray;
  };

  const handleManufactoring = () => {
    return info?.data?.productTypes
      ?.filter((e) => e.productTypeId !== 4)
      .map((item) => ({
        value: item?.productTypeId,
        label: item?.productTypeTranslation[1].name,
      }));
  };

  const segments = [
    {
      value: "b2b",
      label: "Products B2B",
    },
    {
      value: "b2c",
      label: "Products B2C",
    },
  ];

  const tableColumnsAPI = {
    columns: [
      {
        type: "image-component",
        field: "image",
        header: "Article",
        align: "flex-start",
      },
      {
        field: "company",
        header: "Company",
        align: "center",
      },
      {
        field: "type",
        header: "Manufactoring",
        columnFilter: tab === 1 ? "single" : undefined,
        filterName: "manufactoring",
        filter: tab === 1,
        filters: handleManufactoring(),
        align: "center",
      },
      {
        field: "category",
        header: "Category",
        align: "center",
      },
      {
        type: "currency",
        field: "price",
        header: "Price",
        align: "flex-end",
      },
      {
        type: "status-tag",
        field: "active",
        header: "Status",
        align: "center",
      },
    ],
    data: list,
    size: pageSize,
    scroll: {
      scrollable: true,
      scrollableHeight: `${window.innerHeight - 360}px`,
    },
    editrow: {
      hasEdit: false,
    },
    checkboxes: {
      checked: false,
    },
  };

  const getRowUrl = (rowData) => {
    return `/articlesDetails/${rowData.id}`;
  };

  return (
    <div className="page-content">
      <BreadCrumb breadcrumbData={breadcrumbData} />
      <div className="products-content-wrapper">
        <Filters
          filters={filters}
          setFilters={setFilters}
          segments={segments}
          gender={gender}
          categories={handleCategories()}
          entities={entities}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          manufactoring={handleManufactoring()}
          tab={tab}
        />

        <div style={{ width: "100%" }}>
          <UiTabs tab={tab} setTab={setTab} options={items} />

          <div>
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                height: 64,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: 15,
                gap: 10,
              }}
            >
              <div className="products-show-button-blue">
                <Icon color="white" size={[15, 15]} icon="linesIcon" />
              </div>
              <div className="products-show-button">
                <Icon color="#64A0E3" size={[15, 15]} icon="squareIcon" />
              </div>
            </div>
            <Table
              totalItems={totalItems}
              currentPage={page}
              setCurrentPage={setPage}
              rowsPerPage={pageSize}
              setRowsPerPage={setPageSize}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              tableColumns={tableColumnsAPI}
              setList={setList}
              tableInfo={list}
              getRowUrl={getRowUrl}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
