import React from "react";
import { Input, Label } from "reactstrap";
import Icon from "../../icon";

const TextInput = ({
  autoFocus,
  name,
  id,
  placeholder,
  type,
  onChange,
  onBlur,
  value,
  invalid,
  label,
  required,
  disabled,
  icon,
  subtitle,
  searchDisabled,
  readOnly,
  plainText,
  multiple,
  autocomplete,
  onKeyDown,
  iconSize,
  noBorder,
  minLength,
  maxLength,
  loading,
}) => {
  return (
    <div>
      {label &&
        (!loading ? (
          <Label className="heading_4" htmlFor={name}>
            {label}
            {required && <span className="text-danger"> *</span>}
          </Label>
        ) : (
          <div
            className="bg-placeholder"
            style={{ height: 16, width: "15%", marginBottom: 8 }}
          />
        ))}
      <div className="position-relative w-100">
        {!loading ? (
          <>
            <Input
              onKeyDown={onKeyDown}
              style={{ paddingRight: icon ? 32 : 0 }}
              disabled={disabled}
              autoFocus={autoFocus}
              autoComplete={autocomplete}
              name={name}
              id={id}
              minLength={minLength}
              maxLength={maxLength}
              multiple={multiple}
              readOnly={readOnly}
              className={`heading_6 custom-placeholder ${
                plainText
                  ? "form-control-plaintext input-plain-text"
                  : `${
                      noBorder ? "text_input_no_border" : "text-input-style"
                    } form-control ${readOnly && "read-only-input"}`
              } ${searchDisabled && "searchDisabled"} `}
              placeholder={placeholder}
              type={type}
              onChange={readOnly ? undefined : onChange}
              onBlur={onBlur}
              value={value || ""}
              invalid={invalid}
            />
            {icon && (
              <div className="text_input_icon">
                <Icon icon={icon} size={iconSize ? iconSize : [11, 11]} />
              </div>
            )}
          </>
        ) : (
          <div
            className="bg-placeholder"
            style={{ height: 36, width: "100%" }}
          />
        )}
      </div>
      {subtitle && (
        <div
          style={{ marginTop: 5 }}
          className="bodyText_11 input_subtitle_text"
        >
          {subtitle}
        </div>
      )}
    </div>
  );
};

export default TextInput;
